/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { profileAPI } from '../api/profileApi';
import { type RootState } from '../store';
import { ENV } from '../../config/config';
import { type IAccount } from './accounts';

export interface StatisticBlockValuesInterface {
  value: number
  valueInUSD: number
}

export interface StatisticProfitBlockValuesInterface {
  profitable: number
  total: number
}

export interface StatisticsInterface {
  totalBalance: StatisticBlockValuesInterface
  totalDeposits: StatisticBlockValuesInterface
  totalPnL: StatisticBlockValuesInterface
  profitableOrders: StatisticProfitBlockValuesInterface
}

export interface PieChartDataInterface {
  value: number
  label: string
  color: string
}

export interface TabInterface {
  accountId: string
  currency: string
  symbol: string
  type: string
  amount: number
}

export interface BalanceChartDataItemInterface {
  data: Array<number | null>
  label: string
}

export interface BalanceChartDataInterface {
  currency: string
  symbol: string
  amount: number
  XAxis: string[]
  chart: BalanceChartDataItemInterface[]
}

export interface ProfileStateInterface {
  completePercentage: number

  tabsData: TabInterface[] | null
  selectedTab: TabInterface | null

  isBalanceChartDataLoading: boolean
  isBalanceChartErrorOccur: boolean
  balanceChartData: BalanceChartDataInterface | null

  isPieChartDataLoading: boolean
  isPieChartErrorOccur: boolean
  pieData: PieChartDataInterface[] | null

  statistics: StatisticsInterface | null
  isStatiscticLoading: boolean
  isStatisticErrorOccur: boolean
}

const initialState: ProfileStateInterface = {
  completePercentage: 0,

  tabsData: null,
  selectedTab: null,

  isBalanceChartDataLoading: false,
  isBalanceChartErrorOccur: false,
  balanceChartData: null,

  isPieChartDataLoading: false,
  isPieChartErrorOccur: false,
  pieData: null,

  statistics: null,
  isStatiscticLoading: false,
  isStatisticErrorOccur: false
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfileCompletePercent: (state, action: PayloadAction<number>) => {
      state.completePercentage = action.payload;
    },
    setBalanceChartTabsData: (state, action: PayloadAction<TabInterface[] | null>) => {
      state.tabsData = action.payload;
    },
    setBalanceChartTab: (state, action: PayloadAction<TabInterface | null>) => {
      state.selectedTab = action.payload;
    },
    setIsBalanceChartDataLoading: (state, action: PayloadAction<boolean>) => {
      state.isBalanceChartDataLoading = action.payload;
    },
    setIsBalanceChartErrorOccur: (state, action: PayloadAction<boolean>) => {
      state.isBalanceChartErrorOccur = action.payload;
    },
    setBalanceChartData: (state, action: PayloadAction<BalanceChartDataInterface | null>) => {
      state.balanceChartData = action.payload;
    },
    setIsPieChartDataLoading: (state, action: PayloadAction<boolean>) => {
      state.isPieChartDataLoading = action.payload;
    },
    setIsPieChartErrorOccur: (state, action: PayloadAction<boolean>) => {
      state.isPieChartErrorOccur = action.payload;
    },
    setPieData: (state, action: PayloadAction<PieChartDataInterface[] | null>) => {
      state.pieData = action.payload;
    },
    setStatistics: (state, action: PayloadAction<StatisticsInterface>) => {
      state.statistics = action.payload;
    },
    setIsStatisticsErrorOccur: (state, action: PayloadAction<boolean>) => {
      state.isStatisticErrorOccur = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPieChartData.fulfilled, (state) => {
      state.isPieChartDataLoading = false;
    });
    builder.addCase(getPieChartData.pending, (state) => {
      state.isPieChartDataLoading = true;
      state.isPieChartErrorOccur = false;
    });
    builder.addCase(getPieChartData.rejected, (state) => {
      state.isPieChartDataLoading = false;
      state.isPieChartErrorOccur = true;
    });

    builder.addCase(getBalanceChartData.fulfilled, (state) => {
      state.isBalanceChartDataLoading = false;
    });
    builder.addCase(getBalanceChartData.pending, (state) => {
      state.isBalanceChartDataLoading = true;
      state.isBalanceChartErrorOccur = false;
    });
    builder.addCase(getBalanceChartData.rejected, (state) => {
      state.isBalanceChartDataLoading = false;
      state.isBalanceChartErrorOccur = true;
    });
    builder.addCase(getStatistic.pending, (state) => {
      state.isStatiscticLoading = true;
      state.isStatisticErrorOccur = false;
    });
    builder.addCase(getStatistic.rejected, (state) => {
      state.isStatiscticLoading = false;
      state.isStatisticErrorOccur = true;
    });
    builder.addCase(getStatistic.fulfilled, (state) => {
      state.isStatiscticLoading = false;
    });
  }
});

export const {
  setProfileCompletePercent,
  setBalanceChartTabsData,
  setBalanceChartTab,
  setIsBalanceChartDataLoading,
  setIsBalanceChartErrorOccur,
  setBalanceChartData,
  setIsPieChartDataLoading,
  setIsPieChartErrorOccur,
  setPieData,
  setStatistics
} = profileSlice.actions;

export default profileSlice.reducer;

export const getProfileCompletePercent = createAsyncThunk(
  'profile/getProfileCompletePercent',
  async (_: undefined, { dispatch, getState }) => {
    try {
      const { user: { email, profileImage, countryCode, phone } } = getState() as RootState
      const { profileCompletionCriteria } = ENV

      let profPercent = 0

      if (email) {
        profPercent += profileCompletionCriteria.email
      }
      if (countryCode) {
        profPercent += profileCompletionCriteria.address
      }
      if (phone) {
        profPercent += profileCompletionCriteria.phoneNumber
      }
      if (profileImage) {
        profPercent += profileCompletionCriteria.profilePicture
        // setImagePath(`${process.env.REACT_APP_SERVER_URL}/images/${fullUserData?.profileImage}`)
      }
      // ??? Add in future
      // if (userAnalytics?.bankOrCardWithdrawalAccount) {
      //   profPercent += profileCompletionCriteria.bankOrCardForWithdrawal
      // }
      // if (userAnalytics?.firstDeposit) {
      //   profPercent += profileCompletionCriteria.firstDeposit
      // }

      dispatch(setProfileCompletePercent(profPercent))
    } catch (error) {
      console.dir(error);
    }
  }
);

export const getTabsData = createAsyncThunk(
  'profile/getTabsData',
  async (_: undefined, { dispatch, getState }) => {
    try {
      const { accounts } = getState() as RootState

      const sortedByActiveAccounts = [...accounts].sort((a, b) => +b.isActive - +a.isActive);

      const tabsData: TabInterface[] = sortedByActiveAccounts.map((account: IAccount) => {
        return {
          accountId: account._id,
          currency: account.cfdAccountCurrency.symbol,
          type: account.type.name,
          symbol: '', // example: $
          amount: Number(account.balance.toFixed(2))
        }
      })

      dispatch(setBalanceChartTabsData(tabsData))
      dispatch(setBalanceChartTab(tabsData[0]))
    } catch (error) {
      console.log(error);
    }
  }
);

export const getBalanceChartData = createAsyncThunk(
  'profile/getBalanceChartData',
  async (_: undefined, { dispatch, getState }) => {
    try {
      const { profile: { selectedTab } } = getState() as RootState
      const selectedAccountId = selectedTab?.accountId

      if (selectedAccountId === undefined) {
        throw new Error('Account id is not selected')
      }

      const data: any = await profileAPI.getBalanceChartData(selectedAccountId)
      const ordersData: { xAxis: string[], data: Array<number | null> } = data.orders;
      const withdrawalsData: Array<number | null> = data.withdrawals;
      const depositsData: Array<number | null> = data.deposits;
      const exchangesData: Array<number | null> = data.exchanges;

      const balanceChartData: BalanceChartDataInterface = {
        currency: 'USD',
        symbol: '$',
        amount: 999,
        XAxis: ordersData.xAxis,
        chart: [
          {
            data: withdrawalsData,
            label: 'Withdrawal'
          },
          {
            data: depositsData,
            label: 'Deposits'
          },
          {
            data: exchangesData,
            label: 'Exchanges'
          }
        ]
      }

      dispatch(setBalanceChartData(balanceChartData));
    } catch (error) {
      console.dir(error);
      dispatch(setIsBalanceChartErrorOccur(true));
    }
  }
);

export const getPieChartData = createAsyncThunk('profile/getPieChartData', async (_: undefined, { dispatch, getState }) => {
  try {
    const { profile: { selectedTab } } = getState() as RootState
    const selectedAccountId: string | undefined = selectedTab?.accountId

    if (selectedAccountId === undefined) {
      throw new Error('Account id is not selected')
    }

    const data: any = await profileAPI.getPieChartData(selectedAccountId)
    const pieChartData: PieChartDataInterface[] = []

    if (data.GreatThanZeroAmount > 0) {
      pieChartData.push({
        value: data.GreatThanZeroAmount ?? 0,
        label: 'Profit',
        color: 'var(--CFD-theme-State-layers-green-opacity_38)'
      })
    }

    if (data.LowerThanZeroAmount > 0) {
      pieChartData.push({
        value: data.LowerThanZeroAmount ?? 0,
        label: 'Loss',
        color: 'var(--CFD-theme-State-layers-red-opacity_38)'
      })
    }

    dispatch(setPieData(pieChartData));
  } catch (error) {
    console.dir(error);
    dispatch(setIsPieChartErrorOccur(true));
  }
});

export const getStatistic = createAsyncThunk(
  'profile/getStatistic',
  async (accountId: string, { dispatch }) => {
    try {
      const statistics: StatisticsInterface = await profileAPI.getAccountStatistics(accountId);

      dispatch(setStatistics(statistics));
    } catch (error) {
      console.dir(error);
      dispatch(setIsPieChartErrorOccur(true));
    }
  }
)
