import React, { useEffect, type FC } from 'react'
import { UserInfo } from './UserInfo/UserInfo'
import { CompleteProgress } from './CompleteProgress/CompleteProgress'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { selectIsUserDataAvailable, selectUserEmail, selectUserFirstName, selectUserLastName, selectUserProfileImage } from '../../../redux/selectors/user'
import { getProfileCompletePercent } from '../../../redux/reducers/profile'
import { selectProfileCompletePercentage } from '../../../redux/selectors/profile'
import { DividerComponent } from '../../../components'
import style from './ProfileHeader.module.css'

export const ProfileHeader: FC = () => {
  const dispatch = useAppDispatch()

  const isUserDataAvailable = useAppSelector(selectIsUserDataAvailable)

  const userFirstName: string = useAppSelector(selectUserFirstName)
  const userLastName: string = useAppSelector(selectUserLastName)
  const userEmail: string = useAppSelector(selectUserEmail)
  const profileImage: string | null = useAppSelector(selectUserProfileImage)
  const completedPercent: number = useAppSelector(selectProfileCompletePercentage)

  useEffect(() => {
    if (isUserDataAvailable) {
      dispatch(getProfileCompletePercent())
    }
  }, [completedPercent, isUserDataAvailable])

  const userName = `${userFirstName} ${userLastName}`

  return (
    <div className={style.wrapper}>
      <UserInfo userName={userName} email={userEmail} profileImage={profileImage} />
      <div className={style.headerDivider}>
        <DividerComponent width={100} />
      </div>
      <CompleteProgress completedPercent={completedPercent} />
    </div>
  )
}
