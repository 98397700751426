import React, { useEffect, type FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { type RouteLayoutInterface } from '../interfaces';

export const PublicLayout: FC<RouteLayoutInterface> = ({ isUserAuth, children }): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') navigate(isUserAuth ? '/profile' : '/login');
  }, [isUserAuth]);

  return (
    <>
      {children}
    </>
  );
}
