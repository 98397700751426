import React, { type FC } from 'react'
import Avatar from '@mui/material/Avatar';
import style from './UserInfo.module.css'

interface Props {
  userName: string
  email?: string | null
  profileImage: string | null
}

export const UserInfo: FC<Props> = (props) => {
  const { userName, email, profileImage } = props

  const imageLink = process.env.REACT_APP_SERVER_URL + '/images/' + profileImage
  const avatarStyles = { bgcolor: 'var(--CFD-theme-System-Primary)', width: 68, height: 68, color: 'var(--CFD-theme-System-OnPrimary)', textTransform: 'capitalize' }

  return (
    <div className={style.userInfo}>
      <div className={style.userImage}>
        {
          profileImage === null
            ? <Avatar sx={avatarStyles}>{userName[0]}</Avatar>
            : <Avatar sx={avatarStyles} alt={userName} src={imageLink} />
        }
      </div>
      <div className={style.userAdditionalInfo}>
        <div className={style.userName}>
          <span>{userName}</span>
        </div>
        <div className={style.userEmail}>
          <span>{email ?? ''}</span>
        </div>
      </div>
    </div>
  )
}
