import React, { useState, type FC, type MouseEvent, useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { MobileFooter } from './MobileFooter/MobileFooter';
import { useSnackbar } from '../Snackbar/Snackbar';
import { socket } from '../../web/socket'
import { FooterChatComponent } from '../LiveChatComponent/component/FooterChatComponent';
import { getMessage, type MessagesInterface, getChatMessages } from '../../redux/reducers/chat';
import { useWindowSize } from '../../hooks/useWindowSize';

import style from './Footer.module.css';
import { selectIsRunningAdvertisement } from '../../redux/selectors/alertMessage';
import { TransactionModalContext } from '../TransactionModalContext/TransactionModalContext';
import { DepositComponent } from '../../pages/Accounts/DepositSection/DepositComponent';

interface InterfaceFooter {
  setOpenMenu: (el: boolean) => void
}

const soundMessageReceive = new Audio('/Sounds/MessageDelivered.mp3');

export const Footer: FC<InterfaceFooter> = ({ setOpenMenu }) => {
  const { handleOpen } = useSnackbar();

  const dispatch = useAppDispatch();
  const [openLiveChat, setOpenLiveChat] = useState<boolean>(false);
  const [unreadMessages, setUnreadMessages] = useState<number>(0);
  const [openDeposit, setOpenDeposit] = useState<boolean>(false);
  const { _id: userId, brand, isChatEnabled = true } = useAppSelector((state) => state.user);
  const { showFooter } = useAppSelector((state) => state.showFooter);
  const accounts = useAppSelector((state) => state.accounts);
  const chat = useAppSelector((state) => state.chat);
  const isRunningAdvertisement = useAppSelector(selectIsRunningAdvertisement);
  const [screenWidth] = useWindowSize();
  const isMobile = screenWidth <= 600;

  const unreadMessageCount = unreadMessages < 9 ? unreadMessages.toString() : '9+'

  const selectedAccount = useMemo(() => (
    accounts.find((account) => account.isActive) ?? null
  ), [accounts]);

  const handleChatBtn = (event: MouseEvent<HTMLButtonElement>): void => {
    setOpenLiveChat((prevState) => !prevState)
  }

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const getChat = (): void => {
      if (userId !== undefined && isChatEnabled) dispatch(getChatMessages(userId));
    };
    getChat();
  }, [userId, isChatEnabled]);

  useEffect(() => {
    if (chat._id !== undefined && userId !== undefined && isChatEnabled) {
      if (socket !== undefined && socket.connected) {
        socket.on(`getChatMessage/${chat._id}/${chat.user}`, (response: MessagesInterface) => {
          soundMessageReceive.play().catch((error) => {
            console.error('Failed to play sound:', error);
          });
          dispatch(getMessage(response));
        });
      }
    }

    return () => {
      socket.off(`getChatMessage/${chat._id}/${chat.user}`);
    };
  }, [chat, userId, isChatEnabled]);

  useEffect(() => {
    if (chat?.messages?.length > 0 && userId !== undefined) {
      const count = chat.messages.reduce((accumValue, { sender, isRead }) => {
        if (sender !== userId) {
          if (!isRead) {
            return accumValue + 1;
          }
        }
        return accumValue;
      }, 0);
      setUnreadMessages(count);
    }
  }, [chat?.messages, userId]);

  const TransactionModal = useMemo(() => {
    return (
      <TransactionModalContext
        open={ openDeposit }
        setOpen={ setOpenDeposit }
        component={
          <DepositComponent setOpen={ setOpenDeposit } selectedAccount={selectedAccount} />
        }
    />
    )
  }, [openDeposit, selectedAccount]);

  const copyEmail = (email: string): void => {
    navigator.clipboard.writeText(email);

    const openParams = {
      message: 'Email Copied',
      actionText: '',
      autoClose: true
    }

    handleOpen(openParams);
  }

  return (
    <>
      { !isMobile
        ? (
            <footer className={ style.footerWrapper } style={{ bottom: isRunningAdvertisement ? '48px' : '0' }}>
              <div className={ style.footerTitleContainer }>
                <p className={ style.footerTitle }>© {currentYear} {process.env.REACT_APP_BRAND_NAME}</p>
                {(brand?.supportEmail !== undefined) && <p onClick={() => { copyEmail(brand.supportEmail ?? ''); }} className={ style.footerTitleEmail }>{ brand?.supportEmail }</p>}
              </div>
              <div className={ style.footerContainer }>
                {isChatEnabled && (
                  <FooterChatComponent onClickHandler={ handleChatBtn } open={ openLiveChat } unreadMessageCount={ unreadMessageCount } setOpen={ setOpenLiveChat } setOpenDeposit={ setOpenDeposit }/>
                )}
              </div>
            </footer>
          )
        : (showFooter && (
            <MobileFooter
              openLiveChat={ openLiveChat }
              setOpenLiveChat={ setOpenLiveChat }
              handleChatBtn={ handleChatBtn }
              setOpenMenu={ setOpenMenu }
              unreadMessageCount={ unreadMessageCount }
              setOpenDeposit={setOpenDeposit}
              isChatEnabled={ isChatEnabled }
            />
          )
          )}
        { TransactionModal }
    </>
  );
}
