import React, { useMemo, useState } from 'react';
import { t } from 'i18next';

import {
  AssetAmountInputComponent,
  CreditCardInputComponent,
  InputComponent,
  ExpiryDateInputComponent,
  CvvInputComponent,
  ButtonComponent,
  type InterfacePasswordValue,
  useSnackbar,
  SearchSelectComponent
} from '../../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { type OptionInterface, type PayloadInterface } from '../../../../../interfaces';
import { createTransaction, getTransactionsHistory } from '../../../../../redux/reducers/transactionsHistory';
import { handleError } from '../../../../../helpers';

import style from '../CardDepositFormComponent.module.css'

interface PropsType {
  selectedAccount: any
  setStep: any
  selectedCurrencySymbol: any
  currencySelect: OptionInterface
  setCurrencySelect: (el: OptionInterface) => void
  amount: string
  setAmount: any
}

export const CardDepositComponent = ({
  selectedAccount,
  setStep,
  selectedCurrencySymbol,
  currencySelect,
  setCurrencySelect,
  amount,
  setAmount
}: PropsType): JSX.Element => {
  const dispatch = useAppDispatch();
  const { handleOpen: handleOpenSnackbar } = useSnackbar();

  const { _id: userId } = useAppSelector((state) => state.user);
  const currencies = useAppSelector((state) => state.currencies);

  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [cardNumber, setCardNumber] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [expiryDate, setExpiryDate] = useState<string>('');
  const [cvvNumber, setCvvNumber] = useState<InterfacePasswordValue | any>({ value: '', showPassword: false });

  const currenciesOptions = useMemo(() => (
    currencies.map((currency) => ({ label: currency.name, value: currency._id, symbol: currency.symbol }))
  ), [currencies]);

  const searchCurrencyHandler = (value: string | OptionInterface | null): void => {
    if (value === null || typeof value === 'string') return;
    setCurrencySelect(value)
  }

  const handleBtnClick = async (): Promise<void> => {
    if (userId === undefined || selectedAccount === null) return;
    setIsBtnLoading(true);

    const additionalInfo = {
      name: `${firstName} ${lastName}`,
      number: cardNumber,
      cvc: cvvNumber,
      expiry: expiryDate
    };

    const data = {
      userId,
      toAccount: userId,
      userAccountId: selectedAccount._id,
      currencyId: currencySelect.value,
      transactionType: false,
      transactionCategory: 'balance',
      isReal: true,
      isResolved: 0,
      additionalInfo: JSON.stringify(additionalInfo),
      adding: parseFloat(amount).toPrecision(8),
      coins: Number(amount) > 0 ? amount.toString() : '0',
      type: 'Card'
    };

    const response = await dispatch(createTransaction(data));
    const payload = response.payload as PayloadInterface;

    if (payload.isError) {
      handleError(payload, handleOpenSnackbar);
      setIsBtnLoading(false);

      return;
    }

    setStep(2);
    setIsBtnLoading(false);
    dispatch(getTransactionsHistory({ accountId: selectedAccount._id, userId }));
  }

  const clearCurrencySelect = (): void => {
    setCurrencySelect({
      value: '',
      label: '',
      symbol: ''
    })
  }

  const formComplete = (
    amount.length > 0 &&
    Number(amount) > 0 &&
    cardNumber.length > 0 &&
    firstName.length > 0 &&
    lastName.length > 0 &&
    expiryDate.length > 0 &&
    cvvNumber.value.length >= 3 &&
    cvvNumber.value.length <= 4
  ) as boolean;

  return (
    <>
      <div className={ style.cardWrapper }>
        <p className={ style.cardWrapperText }>
          1. {t('deposit_modal.card_form.subtitle1')}
        </p>
        <div className={ style.cardForm }>
          <div className={ style.fullNameWrapper }>
            <div className={ style.fullName }>
              <label className={ style.labelCoin } htmlFor='firstName'>{t('labels.first_name')}</label>
              <InputComponent value={ firstName } setValue={ setFirstName } id='firstName' placeholder={t('labels.first_name')}/>
            </div>
            <div className={ style.fullName }>
              <label className={ style.labelCoin } htmlFor='lastName'>{t('labels.last_name')}</label>
              <InputComponent value={ lastName } setValue={ setLastName } id='lastName' placeholder={t('labels.last_name')}/>
            </div>
          </div>
          <div className={ style.creditCardWrapper }>
            <label htmlFor='cardNumber' className={ style.labelCoin }>{t('deposit_modal.card_form.card_label')}</label>
            <CreditCardInputComponent value={ cardNumber } setValue={ setCardNumber } id='cardNumber' placeholder='0000 0000 0000 0000'/>
          </div>
          <div className={ style.fullNameWrapper }>
            <div className={ style.fullName }>
              <label className={ style.labelCoin } htmlFor='expiryDate'>{t('labels.expiration_date')}</label>
              <ExpiryDateInputComponent value={ expiryDate } setValue={ setExpiryDate } id='expiryDate'/>
            </div>
            <div className={ style.fullName }>
              <label className={ style.labelCoin } htmlFor='lastName'>{t('labels.cvv')}</label>
              <CvvInputComponent value={ cvvNumber } setValue={ setCvvNumber }/>
            </div>
          </div>
        </div>
      </div>
      <div className={ style.cardWrapper }>
        <p className={ style.cardWrapperText }>
          2. {t('deposit_modal.card_form.subtitle2')}
        </p>
        <div className={ style.selectWrapper }>
          <label htmlFor='currency' className={ style.labelCoin }>{t('labels.currency')}</label>
            <SearchSelectComponent
              options={ currenciesOptions }
              value={ currencySelect }
              clearState={ clearCurrencySelect }
              onSearch={ searchCurrencyHandler }
              id='currency'
              placeholder={ `${t('labels.select_currency')}` }
            />
        </div>
        <div className={ style.selectWrapper }>
          <label htmlFor='amount' className={ style.labelCoin }>{t('labels.amount')}</label>
          <AssetAmountInputComponent
            disabled={currencySelect.value.length === 0}
            value={ amount }
            setValue={ setAmount }
            id='amount'
            currency={selectedCurrencySymbol}
            maxBtn={ false }
            placeholder='0.00'
          />
        </div>
      </div>
      <footer className={ style.footer }>
        <div className={ style.btnContainer }>
          <ButtonComponent onClick={() => { handleBtnClick(); } } disabled={ !formComplete } loading={isBtnLoading}>
            <span className={ style.btnText }>
              {t('labels.confirm')}
            </span>
          </ButtonComponent>
        </div>
      </footer>
    </>
  )
}
