import React, { useMemo, type FC, type MouseEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/redux';
import { t } from 'i18next';

import { MobileChatIcon } from '../../icons/MobileChatIcon';
import { type MenuItemInterface } from '../../NavigationRail/NavigationRail';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { LiveChatComponent } from '../../LiveChatComponent/LiveChatComponent';
import { ProfileIcon } from '../../icons/ProfileIcon';
import { AccountsIcon } from '../../icons/AccountsIcon';

import style from '../Footer.module.css';

interface InterfaceMobileFooter {
  openLiveChat: boolean
  isChatEnabled: boolean
  setOpenLiveChat: (el: boolean) => void
  handleChatBtn: (e: MouseEvent<HTMLButtonElement>) => void
  setOpenMenu: (el: boolean) => void
  unreadMessageCount: string
  setOpenDeposit: (el: boolean) => void
}

export const MobileFooter: FC<InterfaceMobileFooter> = ({ openLiveChat, setOpenLiveChat, handleChatBtn, setOpenMenu, unreadMessageCount, setOpenDeposit, isChatEnabled }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [widthScreen] = useWindowSize();
  const { startAsset } = useAppSelector(state => state.cfd);
  const isMobile = widthScreen <= 599;

  const menuItemsData = useMemo(() => [
    { link: '/profile', logo: <ProfileIcon />, title: t('labels.profile') },
    { link: '/accounts', logo: <AccountsIcon />, title: t('labels.accounts') }
  ], [t, startAsset]);

  const handleMobileChatBtn = (event: MouseEvent<HTMLButtonElement>, link: string): void => {
    navigate(link);
    setOpenLiveChat(false);
    if (isMobile) setOpenMenu(false);
  }

  return (
    <>
      <footer className={ style.mobileFooterWrapper }>
      {
        menuItemsData.map(({ link, logo, title }: MenuItemInterface, key) => {
          const isSelected = link === location.pathname || link.startsWith(location.pathname) || location.pathname.startsWith(link);
          return (
            <div className={ style.mobileNavCell } key={ key }>
              <button className={ style.mobileBtnContainer } onClick={(e) => { handleMobileChatBtn(e, link); } }>
                  <div className={
                    `${style.menuItemImageWrapper} ${(isSelected && !openLiveChat) ? style.selectedMenuImageItem : null}`} >
                    { logo }
                  </div>
                  <span className={ style.menuLink } >
                    { title }
                  </span>
              </button>
            </div>
          )
        })
      }
      {
        isChatEnabled && (
          <div className={ style.chatCell }>
          <button className={ style.chatMobileBtn } onClick={ handleChatBtn }>
            <div className={ `${style.iconWrapper} ${openLiveChat ? style.iconWrapperChecked : null}` }>
              <div className={ style.badgeWrapper }>
                <p className={ style.badgeWrapperText }>
                  { unreadMessageCount }
                </p>
              </div>
              <MobileChatIcon />
            </div>
            <span className={ style.chatbtnText }>{ t('footer.labels.live_chat') }</span>
          </button>
        </div>
        )
      }
    </footer>
    { (openLiveChat && isChatEnabled) &&
      <LiveChatComponent
        setOpen={ setOpenLiveChat }
        open={ openLiveChat }
        setOpenDeposit={ setOpenDeposit }
      />}
    </>
  )
}
