import USAIcon from '../assets/images/USA.svg';
import EURIcon from '../assets/images/Europe (EUR).svg';
import CADIcon from '../assets/images/CanadaFlag.svg';
import GBPIcon from '../assets/images/GreatBritain.svg';
import AUDIcon from '../assets/images/Australia.svg';
import VipAccount from '../assets/images/accountTypeImages/24x24/Icons status_VIP.svg';
import PlatinumAccount from '../assets/images/accountTypeImages/24x24/Icons status_Platinum.svg';
import GoldAccount from '../assets/images/accountTypeImages/24x24/Icons status_Gold.svg';
import SilverAccount from '../assets/images/accountTypeImages/24x24/Icons status_Silver.svg';
import BronzeAccount from '../assets/images/accountTypeImages/24x24/Icons status_Bronze.svg';
import NZDIcon from '../assets/images/New-Zealand.svg';
import JPYIcon from '../assets/images/Japan.svg';
import CHFIcon from '../assets/images/Swiss 20.svg';
import BitcoinCashIcon from '../assets/images/Bitcoin-Cash.svg';
import LiteCoinIcon from '../assets/images/Litecoin.svg';
import DashIcon from '../assets/images/Dash.svg';
import PolkadotIcon from '../assets/images/Polkadot.svg';
import ThetaIcon from '../assets/images/Theta-Network.svg'
import KusamaIcon from '../assets/images/kusama.svg';
import ElrondIcon from '../assets/images/Elrond.svg';
import StellarIcon from '../assets/images/Stellar.svg';
import SolanaIcon from '../assets/images/Solana.svg';
import InternetComputerIcon from '../assets/images/Internet Comp.svg';
import AlgorandIcon from '../assets/images/Algorand.svg';
import MakerIcon from '../assets/images/Maker.svg';
import DogecoinIcon from '../assets/images/Dogecoin.svg';
import ChainlinkIcon from '../assets/images/ChainLink.svg';
import BitcoinIcon from '../assets/images/Bitcoin.svg';
import EtheriumClasicIcon from '../assets/images/Ethereum Classic.svg';
import TetherIcon from '../assets/images/USDT.svg';
import EosIcon from '../assets/images/EOS.svg';
import VeChainIcon from '../assets/images/VeChain.svg';
import MoneroIcon from '../assets/images/Monero.svg';
import CosmosIcon from '../assets/images/Cosmos.svg';
import CardanoIcon from '../assets/images/Cardano.svg';
import FilecoinIcon from '../assets/images/Filecoin.svg';
import TezosIcon from '../assets/images/Tezos.svg';
import RippleIcon from '../assets/images/Ripple.svg';
import EthereumIcon from '../assets/images/Ethereum.svg'
import CompoundIcon from '../assets/images/comp.svg';
import BinanceCoinIcon from '../assets/images/Binance Coin.svg';
import AAVEIcon from '../assets/images/AAVE.svg';
import SynthetixIcon from '../assets/images/Synthetix Network.svg';
import TronIcon from '../assets/images/TRON.svg';
import AvalancheIcon from '../assets/images/Avalanche.svg';
import USDCoinIcon from '../assets/images/usd-coin.svg';
import UniswapIcon from '../assets/images/Uniswap.svg';
import FtxCoinIcon from '../assets/images/FTX.svg';
import PolygonIcon from '../assets/images/Polygon.svg';
import NEOIcon from '../assets/images/NEO.svg';
import { type SavingOption } from '../interfaces/SavingInterface';

export const toFixed = (num: number, fixed: number): number => {
  if (typeof num === 'undefined' || Number.isNaN(Number(num))) return 0;

  const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed ?? -1}})?`);
  const match = num.toString().match(re);

  return match !== null ? Number(match[0]) : 0;
}

const padTo2Digits = (num: number): string => num.toString().padStart(2, '0');

export const formatDate = (date: Date): string => (
    `${[
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate())
    ].join('-')
    } ${
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds())
      ].join(':')}`
);

export const getAccountIconBySymbol = (symbol: string): string | undefined => {
  const symbolToIcon: Record<string, string | undefined> = {
    USD: USAIcon,
    CAD: CADIcon,
    AUD: AUDIcon,
    GBP: GBPIcon,
    EUR: EURIcon,
    CHF: CHFIcon,
    JPY: JPYIcon,
    NZD: NZDIcon,
    BCH: BitcoinCashIcon,
    LTC: LiteCoinIcon,
    DASH: DashIcon,
    DOT: PolkadotIcon,
    THETA: ThetaIcon,
    KSM: KusamaIcon,
    EGLD: ElrondIcon,
    XLM: StellarIcon,
    SOL: SolanaIcon,
    ICP: InternetComputerIcon,
    ALGO: AlgorandIcon,
    MKR: MakerIcon,
    DOGE: DogecoinIcon,
    LINK: ChainlinkIcon,
    BTC: BitcoinIcon,
    ETC: EtheriumClasicIcon,
    USDT: TetherIcon,
    EOS: EosIcon,
    VET: VeChainIcon,
    XMR: MoneroIcon,
    ATOM: CosmosIcon,
    ADA: CardanoIcon,
    FIL: FilecoinIcon,
    XTZ: TezosIcon,
    XRP: RippleIcon,
    ETH: EthereumIcon,
    COMP: CompoundIcon,
    BNB: BinanceCoinIcon,
    AAVE: AAVEIcon,
    SNX: SynthetixIcon,
    TRX: TronIcon,
    AVAX: AvalancheIcon,
    USDC: USDCoinIcon,
    UNI: UniswapIcon,
    FTT: FtxCoinIcon,
    MATIC: PolygonIcon,
    NEO: NEOIcon
  }

  return symbolToIcon[symbol];
}

export const getCurrencySymbol = (symbol: string): string => {
  const symbols: Record<string, string> = {
    USD: '$',
    EUR: '€',
    CAD: '$',
    AUD: '$',
    GBP: '£'
  };

  return symbols[symbol] ?? symbol;
};

export const getAccountTypeIconByName = (name: string): string => {
  const accountTypeNameToIcon: Record<string, string> = {
    Silver: SilverAccount,
    Gold: GoldAccount,
    Platinum: PlatinumAccount,
    Bronze: BronzeAccount,
    VIP: VipAccount
  };

  return accountTypeNameToIcon[name] ?? '';
}

export const calculateReleaseTime = ({ subscriptionDate, option }: { subscriptionDate: Date, option: SavingOption }): Date => {
  const { durationType, period } = option;
  const releaseTime = new Date(subscriptionDate.getTime());

  switch (durationType) {
    case 'minutes':
      releaseTime.setMinutes(releaseTime.getMinutes() + period);
      break;
    case 'hours':
      releaseTime.setHours(releaseTime.getHours() + period);
      break;
    case 'day':
      releaseTime.setDate(releaseTime.getDate() + period);
      break;
    case 'week':
      releaseTime.setDate(releaseTime.getDate() + period * 7);
      break;
    case 'month':
      releaseTime.setMonth(releaseTime.getMonth() + period);
      break;
    case 'year':
      releaseTime.setFullYear(releaseTime.getFullYear() + period);
      break;
    default:
      throw new Error('Unsupported duration type');
  }

  return releaseTime;
}

export const calculateSavingFlexibleLockPeriod = (createdDate: string): number => {
  const created = new Date(createdDate as Date | string).getTime();
  const now = new Date().getTime();
  return Math.floor((now - created) / (1000 * 60 * 60 * 24));
};

export const getDaysInYear = (year = new Date().getFullYear()): number => {
  const IS_LEAP_YEAR = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  return IS_LEAP_YEAR ? 366 : 365;
};

export const calcSavingFlexibleEarnings = (amt: number, percentage: number, noOfDays: number, toFixed: number): string => {
  const dailyEarnings = (percentage / (getDaysInYear() * 100)) * amt;
  return ((noOfDays * dailyEarnings) + amt).toFixed(toFixed)
};

export const calculatePercentage = (part: number = 0, whole: number = 1): number => {
  if (whole !== 0) {
    return (part / whole) * 100;
  }

  return 0;
};

export const getTradeTypeNameByIndex = (index: number): string => {
  const tradeTypes = ['Sell', 'Buy', 'Buy Limit', 'Buy Stop', 'Sell Limit', 'Sell Stop'];

  return tradeTypes[index];
}
