import React, { type FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { getStatistic } from '../../../redux/reducers/profile';
import { getProfileSelectedTab } from '../../../redux/selectors/profile';
import { type TabInterface } from '../../../redux/reducers/profile';
import { ProgressComponent } from '../../../components';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import DepositIcon from '../../../assets/images/deposit.svg';
import styles from './Statistic.module.css';
import { t } from 'i18next'

export const Statistic: FC = () => {
  const dispatch = useAppDispatch();
  const { statistics, isStatiscticLoading } = useAppSelector((state) => state.profile);
  const activeAccount: TabInterface | null = useAppSelector(getProfileSelectedTab)

  useEffect(() => {
    if (activeAccount !== null) {
      dispatch(getStatistic(activeAccount.accountId))
    }
  }, [activeAccount]);

  return (
    <div className={styles.statisticsContent}>
        <div className={styles.statisticBlock}>
            {
                !isStatiscticLoading
                  ? (
                    <div className={styles.statisticBlockRow}>
                    <div className={styles.statisticBlockContent}>
                        <div className={styles.statisticBlockHeader}>
                            <div className={styles.statisticBlockHeaderIcon}>
                                <AccountBalanceWalletOutlinedIcon />
                            </div>
                            {t('profile_page.labels.total_balance')}:
                        </div>
                        <div className={styles.statisticBlockBalance}>
                            <span className={styles.statisticBlockValue}>{activeAccount?.currency ?? ''}</span>
                            <span className={styles.statisticBlockValue}>{statistics?.totalBalance.value ?? '0.00'}</span>
                        </div>
                    </div>
                    <div className={styles.statisticBlockContent}>
                        <div className={styles.statisticBlockBalance}>
                            <span className={styles.statisticBlockSubValue}>{t('profile_page.labels.in_usd')}</span>
                            <span className={styles.statisticBlockSubValue}>{statistics?.totalBalance.valueInUSD ?? '0.00'}</span>
                        </div>
                    </div>
                </div>
                    )
                  : (
                    <ProgressComponent type="circular" />
                    )
            }

        </div>
        <div className={styles.statisticBlock}>
            {
                !isStatiscticLoading
                  ? (
                    <div className={styles.statisticBlockRow}>
                    <div className={styles.statisticBlockContent}>
                    <div className={styles.statisticBlockHeader}>
                        <div className={styles.statisticBlockHeaderIcon}>
                            <svg>
                                <use xlinkHref={`${DepositIcon}#deposit`}></use>
                            </svg>
                            </div>
                            {t('profile_page.labels.total_deposit')}
                        </div>
                        <div className={styles.statisticBlockBalance}>
                            <span className={styles.statisticBlockValue}>{activeAccount?.currency ?? ''}</span>
                            <span className={styles.statisticBlockValue}>{statistics?.totalDeposits.value ?? '0.00'}</span>
                        </div>
                    </div>
                    <div className={styles.statisticBlockContent}>
                        <div className={styles.statisticBlockBalance}>
                            <span className={styles.statisticBlockSubValue}>{t('profile_page.labels.in_usd')}</span>
                            <span className={styles.statisticBlockSubValue}>{statistics?.totalDeposits.valueInUSD ?? '0.00'}</span>
                        </div>
                    </div>
                </div>
                    )
                  : (
                    <ProgressComponent type="circular" />
                    )
            }
        </div>
    </div>
  )
}
