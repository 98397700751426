import React, { type FC, type MouseEvent, useEffect, useState } from 'react';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { t } from 'i18next';

import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import reciveDeposit from '../../../../assets/images/recive_deposit.svg';
import transferIcon from '../../../../assets/images/transferAccountIcon.svg';
import withdrawalAcc from '../../../../assets/images/withdrawal_accounts.svg';
import { activateAccount, type IAccount } from '../../../../redux/reducers/accounts';
import { getCurrencySymbol, toFixed } from '../../../../helpers/util';
import { AccountTypeIcon } from './AccountTypeIcon/AccountTypeIcon';
import { DividerComponent } from '../../../../components';

import style from './AccountsTableHeader.module.css';

interface InterfaceAccountsTableHeader {
  setOpenDeposit: (el: boolean) => void
  setOpenWithdrawal: (el: boolean) => void
  setOpenTransactions: (el: boolean) => void
  selectedAccount: IAccount | null
  setSelectedAccount: (el: IAccount | null) => void
}

const visibilityBalanceIconStyle = {
  cursor: 'pointer',
  width: '20px',
  height: '20px',
  color: 'var(--CFD-theme-System-Outline)'
}

export const AccountsTableHeader: FC<InterfaceAccountsTableHeader> = ({ setOpenDeposit, setOpenWithdrawal, selectedAccount, setOpenTransactions, setSelectedAccount }) => {
  const dispatch = useAppDispatch();
  const { _id: userId } = useAppSelector((state) => state.user);
  const accounts = useAppSelector((state) => state.accounts);
  const [accountIsActiveId, setAccountIsActiveId] = useState<string | null>(null);
  const [totalBalance, setTotalBalance] = useState<number>(0)
  const [isBalanceVisible, setIsBalanceVisible] = useState<boolean>(true);

  const depositBtnHandler = (account: MouseEvent<HTMLButtonElement>): void => {
    setOpenDeposit(true);
  }

  const witdrawBtnHandler = (event: MouseEvent<HTMLButtonElement>): void => {
    setOpenWithdrawal(true);
  }

  const transactionsBtnHandler = (event: MouseEvent<HTMLButtonElement>): void => {
    setOpenTransactions(true);
  }

  const balanceVisibility = (event: MouseEvent<SVGSVGElement>): void => {
    setIsBalanceVisible((prevState: boolean) => !prevState)
  }

  useEffect(() => {
    const activeAccountId = accounts.find(({ isActive }) => isActive)?._id;
    if (activeAccountId == null) return;
    setAccountIsActiveId(activeAccountId);
  }, [accounts]);

  useEffect(() => {
    const ballanceSelectedAccount = accounts.find(({ _id }) => _id === selectedAccount?._id)?.balance;
    if (ballanceSelectedAccount == null) return;
    setTotalBalance(ballanceSelectedAccount)
  }, [accounts, selectedAccount])

  const selectAccountHandler = async (): Promise<void> => {
    const accountId = selectedAccount?._id ?? '';
    if ((accountId?.length === 0) || (userId == null)) return;
    await dispatch(activateAccount({ userId, accountId }));
    setSelectedAccount(selectedAccount);
  }

  return (
      <>
        {
          selectedAccount !== null && (
            <div className={ style.headerWrapper }>
              <div className={ style.header }>
                <div className={ style.optionsContainer }>
                  <div className={ style.optionsStatusContainer }>
                    <AccountTypeIcon
                      selectedAccount={selectedAccount}
                    />
                    {
                      selectedAccount._id === accountIsActiveId
                        ? (<div className={ style.selectedAccount }>
                            <p className={ style.selectedAccountText }>{t('labels.selected_account')}</p>
                          </div>)
                        : (<button className={ style.selectBtn } onClick={() => { selectAccountHandler(); } }>{t('labels.make_selected_account')}</button>)
                    }
                  </div>
                  <div className={ style.balanceContainer }>
                      <p className={ style.balanceText }>
                        {t('labels.total_ballance')}
                      </p>
                      { isBalanceVisible
                        ? <VisibilityIcon onClick={ balanceVisibility } style={ visibilityBalanceIconStyle }/>
                        : <VisibilityOffIcon onClick={ balanceVisibility } style={ visibilityBalanceIconStyle }/> }
                  </div>
                  <div className={ style.sumContainer }>
                      { isBalanceVisible
                        ? <>
                      <p className={ style.sumText }>{toFixed(totalBalance, 2)}</p>
                      <p className={ style.sumText }>{selectedAccount.cfdAccountCurrency.symbol}</p>
                      </>
                        : null }
                  </div>
                </div>
                <hr className={ style.line }/>
                <div className={ style.optionsBtnsContainer }>
                  <div className={ style.optionsBtnsWrapper }>
                    <div className={ style.depositBtnsContainer }>
                      <button className={ style.btn } onClick={ depositBtnHandler }>
                        <svg className={ style.svg }>
                          <use xlinkHref={`${reciveDeposit}#reciveDeposit`}/>
                        </svg>
                        <p className={ style.btnText }>{t('labels.deposit')}</p>
                      </button>
                    </div>
                    <div className={ style.withdrawBtnsContainer }>
                      <button disabled={selectedAccount.balance <= 0} className={ style.btn } onClick={ witdrawBtnHandler }>
                        <svg className={ style.svg }>
                          <use xlinkHref={`${withdrawalAcc}#withdrawalAcc`}/>
                        </svg>
                        <p className={ style.btnText }>{t('labels.withdrawal')}</p>
                      </button>
                    </div>
                    { accounts.length > 1 && <div className={ style.transferBtnsContainer }>
                      <button className={ style.btn } onClick={ transactionsBtnHandler }>
                        <svg className={ style.svg }>
                          <use xlinkHref={`${transferIcon}#transferIcon`}/>
                        </svg>
                        <p className={ style.btnText }>{t('labels.transfer')}</p>
                      </button>
                    </div> }
                  </div>
                  <div className={ style.options }>
                    <div className={ style.credit }>
                      <p className={ style.creditInvestedText }>{t('labels.credit')}</p>
                      <p className={ style.amountCreditInvestedText }>{`${getCurrencySymbol(selectedAccount.cfdAccountCurrency.symbol)} ${toFixed(selectedAccount.credit, 2)}`}</p>
                    </div>
                    <div className={ style.optionsLine }>
                      <DividerComponent orientation='vertical'/>
                    </div>
                    <div className={ style.invested }>
                      <p className={ style.creditInvestedText }>{t('labels.rent')}</p>
                      <p className={ style.amountCreditInvestedText }>{`${getCurrencySymbol(selectedAccount.cfdAccountCurrency.symbol)} ${toFixed(selectedAccount.rent, 2)}`}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </>
  )
}
