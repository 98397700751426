import React, { type FC } from 'react';
import darkLogo from '../../assets/images/logos/darkLogoLong.svg';
import lightLogo from '../../assets/images/logos/lightLogoLong.svg';
import { useTheme } from '../ThemeProvider/ThemeProvider';

export const DefaultLogo: FC = () => {
  const { theme } = useTheme();

  return (
    theme === 'dark'
      ? (
        <img src={darkLogo} alt="logo" />
        )
      : (
        <img src={lightLogo} alt="logo" />
        )
  );
};
