import React, { type FC, type MouseEvent } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { t } from 'i18next';

import { activateAccount, type IAccount } from '../../../redux/reducers/accounts';
import { useAppSelector, useAppDispatch } from '../../../hooks/redux';
import { getAccountIconBySymbol, getCurrencySymbol, toFixed } from '../../../helpers/util';
import { RadioButtonComponent } from '../../RadioButtonComponent/RadioButtonComponent';
import reciveDeposit from '../../../assets/images/recive_deposit.svg';
import withdrawalAcc from '../../../assets/images/withdrawal_accounts.svg';

import styles from './CellHeaderAccountSelect.module.css';

interface ICellHeaderAccountSelect {
  account: IAccount
  depositBtnHandler: (event: MouseEvent<HTMLButtonElement>, account: IAccount) => void
  withdrawalBtnHandler: (event: MouseEvent<HTMLButtonElement>, account: IAccount) => void
  linkBtnHandler: (event: MouseEvent<HTMLButtonElement>, accountId: string) => void
  selectedRadioValue: string
  setSelectedRadioValue: (el: string) => void
}

interface IAccountInfoValue {
  label: string
  value: string
}

export const CellHeaderAccountSelect: FC<ICellHeaderAccountSelect> = ({
  selectedRadioValue,
  account,
  depositBtnHandler,
  withdrawalBtnHandler,
  linkBtnHandler,
  setSelectedRadioValue

}) => {
  const dispatch = useAppDispatch();
  const { _id: userId } = useAppSelector((state) => state.user);

  const accountSymbol = getCurrencySymbol(account?.cfdAccountCurrency.symbol ?? '');

  const selectAccountHandler = async (accountId: string): Promise<void> => {
    if ((accountId.length === 0) || (userId == null)) return;
    await dispatch(activateAccount({ userId, accountId }))
    setSelectedRadioValue(accountId)
  }

  const stopPropagation = (event: MouseEvent): void => {
    event.stopPropagation();
  };

  const accountInfoValue: IAccountInfoValue[] = [
    { label: t('header.labels.credit'), value: `${accountSymbol} ${toFixed(account?.credit ?? 0, 2)}` },
    { label: t('labels.rent'), value: `${accountSymbol} ${toFixed(account?.rent ?? 0, 2)}` }
  ];

  return (
    <div
      key={ account._id }
      className={ styles.optionsCellWrapper }
      onClick={ () => { selectAccountHandler(account._id); } }
    >
      <div className={ styles.optionsCellHeader }>
        <div className={ styles.optionsCellHeaderTitle }>
          <img src={ getAccountIconBySymbol(account?.cfdAccountCurrency?.symbol ?? '') } className={ styles.imgSize }/>
          <p className={ styles.optionsCellHeaderText }>{`${account?.type?.name ?? ''} ${t('labels.account')} ${account?.cfdAccountCurrency?.symbol ?? ''}`}</p>
          <button className={ styles.linkBtn } onClick={(e) => { linkBtnHandler(e, account._id); } }>
            <OpenInNewIcon sx={{
              width: '16px',
              height: '16px',
              cursor: 'pointer',
              color: 'var(--CFD-theme-System-Secondary)',
              '&:hover': {
                color: 'var(--CFD-theme-System-Primary)'
              }
            } } />
          </button>
        </div>
        <RadioButtonComponent
          style={{ height: '36px', width: '36px' }}
          value={ account._id }
          checked={ account._id === selectedRadioValue }
        />
      </div>
      <div className={ styles.optionsCellBody } onClick={ stopPropagation }>
        <div className={ styles.amountSection }>
          <p className={ styles.amountSectionText }>
            { accountSymbol }
            <span className={ styles.amountSectionSpan } >{ toFixed(account?.balance, 2) }</span>
          </p>
          <div className={ styles.btnsContainer }>
            <button className={ styles.depositBtn } onClick={(e) => { depositBtnHandler(e, account); } }>
              <svg className={ styles.svg }>
                <use xlinkHref={`${reciveDeposit}#reciveDeposit`}/>
              </svg>
              <span className={styles.svgTitle}>{t('header.deposit_button_title')}</span>
            </button>
            <button
              className={ account.balance !== 0 ? `${styles.withdrawalBtn}` : `${styles.disableWithdrawalBtn}` }
              disabled={ account.balance === 0 }
              onClick={(e) => { withdrawalBtnHandler(e, account); } }
            >
              <svg className={ styles.svg }>
                <use xlinkHref={`${withdrawalAcc}#withdrawalAcc`}/>
              </svg>
            </button>
          </div>
        </div>
        <div className={ styles.infoSection } onClick={ stopPropagation }>
          {
            accountInfoValue.map((item, index) => {
              return (
                <div className={ styles.infoSectionCell } key={ index }>
                  <p className={ styles.infoSectionCellTitle }>{ item.label }</p>
                  <p className={ styles.infoSectionCellText }>{ item.value }</p>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  )
}
