import React, { type FC } from 'react';
import { t } from 'i18next';

import styles from './BankDepositInvoice.module.css';

interface BankDepositInvoiceProps {
  userName: string
  formattedDate: string
  currencySymbol: string
  amount: string
  accountNumber: string
  bankName: string
  swift: string
  iban: string
  bankAddress: string
  customId: string
  transacctionId: string
  currency: string
}

export const BankDepositInvoice: FC<BankDepositInvoiceProps> = ({
  userName,
  formattedDate,
  currencySymbol,
  amount,
  accountNumber,
  bankName,
  swift,
  iban,
  bankAddress,
  customId,
  transacctionId,
  currency
}): JSX.Element => {
  return (
    <div className={styles.invoiceWrapper} id="invoice">
      <h1 className={styles.invoiceHeader}>{t('labels.bank_transfer')}</h1>
      <div className={styles.invoiceInfoWrapper}>
        <div className={styles.invoiceInfoHeading}>
          <div className={styles.invoiceInfoElement}>
              <span className={`${styles['gray-bold']} ${styles['font-14']}`}>{t('labels.billed_to')}</span>
              <span className={`${styles['black-bold']} ${styles['font-14']}`}>{userName}</span>
              </div>
              <div className={styles.invoiceInfoElement}>
                <span className={`${styles['gray-bold']} ${styles['font-14']}`}>{t('labels.invoice_date')}</span>
                <span className={`${styles['black-bold']} ${styles['font-14']}`}>{formattedDate}</span>
              </div>
              <div className={styles.invoiceInfoElement}>
                <span className={`${styles['gray-bold']} ${styles['font-14']} ${styles['text-right']}`}>{t('labels.invoice_of')} ({currency})</span>
                <span className={`${styles['blue-color']} ${styles['font-20']}`}>{currencySymbol}{amount}</span>
              </div>
            </div>
            <div className={styles.paymentDetailsWrapper}>
              <span className={`${styles['gray-bold']} ${styles['font-14']}`}>{t('labels.payment_details')}</span>
              <div className={styles.paymentDetailsInfo}>
                <div className={styles.paymentDetailsInfoElement}>
                  <span className={`${styles['gray-bold']} ${styles['font-14']}`}>{t('labels.account')}: </span>
                  <span className={`${styles['black-bold']} ${styles['font-14']}`}>{accountNumber}</span>
                </div>
                <div className={styles.paymentDetailsInfoElement}>
                  <span className={`${styles['gray-bold']} ${styles['font-14']}`}>{t('labels.bank_name')}: </span>
                  <span className={`${styles['black-bold']} ${styles['font-14']}`}>{bankName}</span>
                </div>
                <div className={styles.paymentDetailsInfoElement}>
                  <span className={`${styles['gray-bold']} ${styles['font-14']}`}>{t('labels.swift')}: </span>
                  <span className={`${styles['black-bold']} ${styles['font-14']}`}>{swift}</span>
                </div>
                <div className={styles.paymentDetailsInfoElement}>
                  <span className={`${styles['gray-bold']} ${styles['font-14']}`}>{t('labels.iban')}: </span>
                  <span className={`${styles['black-bold']} ${styles['font-14']}`}>{iban}</span>
                </div>
                <div className={styles.paymentDetailsInfoElement}>
                  <span className={`${styles['gray-bold']} ${styles['font-14']}`}>{t('labels.bank_address')}: </span>
                  <span className={`${styles['black-bold']} ${styles['font-14']}`}>{bankAddress}</span>
                </div>
                <div className={styles.paymentDetailsInfoElement}>
                  <span className={`${styles['gray-bold']} ${styles['font-14']}`}>{t('labels.reference')}: </span>
                  <span className={`${styles['black-bold']} ${styles['font-14']}`}>{userName}</span>
                </div>
              </div>
            </div>
            <div className={styles.descriptionDetailsWrapper}>
              <div className={styles.descriptionDetailsInfoHeading}>
                <span>{t('labels.description')}</span>
                <span>{t('labels.amount')}</span>
              </div>
              <div className={styles.desciptionDetailsAmount}>
                <span style={{ fontSize: '10px', fontWeight: 700 }}>{customId} / {transacctionId}</span>
                <span>{currencySymbol}{amount}</span>
              </div>
              <div className={styles.subtotal}>
                <div className={`${styles.mediumWidth} ${styles['border-b']}`}>
                  <span>{t('labels.subtotal')}</span>
                  <span>{currencySymbol}{amount}</span>
                </div>
              </div>
              <div className={styles.total}>
                <div className={styles.mediumWidth}>
                  <span style={{ fontSize: '10px', fontWeight: 700 }}>{t('labels.total')}</span>
                  <span style={{ fontSize: '10px', fontWeight: 700 }}>{currencySymbol}{amount}</span>
                </div>
          </div>
        </div>
      </div>
    </div>
  )
}
