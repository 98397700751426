import React, { type FC } from 'react';
import logo from '../../assets/images/logos/logo.svg'

export const LogoIcon: FC = () => {
  return (
    <>
      <img src={logo} alt="logo" />
    </>
  );
};
