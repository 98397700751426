import React, { type FC, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { getDefaultBank } from '../../../../redux/reducers/psp';
import {
  AssetAmountInputComponent,
  ButtonComponent,
  InputComponent
} from '../../../../components';
import { SuccessDepositFormComponent } from '../SuccessDepositFormComponent/SuccessDepositFormComponent';

import style from './BankDepositFormComponent.module.css';
import { t } from 'i18next';
import { createTransaction, getTransactionsHistory } from '../../../../redux/reducers/transactionsHistory';
import { type PayloadInterface } from '../../../../interfaces';
import { type IAccount } from '../../../../redux/reducers/accounts';
import { getCurrencySymbol } from '../../../../helpers/util';
import { BankDepositInvoice } from './BankDepositInvoice';
import { generatePdf } from '../../../../helpers/generatePdf';

interface InterfaceBankDepositFormComponent {
  setStep: (el: number) => void
  step: number
  setOpen: (el: boolean) => void
  selectedAccount: IAccount | null
}

export const BankDepositFormComponent: FC<InterfaceBankDepositFormComponent> = ({ setOpen, step, setStep, selectedAccount }) => {
  const { defaultBank } = useAppSelector((state) => state.psp);

  const dispatch = useAppDispatch();
  const [bankName, setBankName] = useState<string>(defaultBank?.name ?? '')
  const [iban, setIban] = useState<string>(defaultBank?.iban ?? '');
  const [accountNumber, setAccountNumber] = useState<string>(defaultBank?.accountNumber ?? '');
  const [bankAddress, setBankAddress] = useState<string>(defaultBank?.bankAddress ?? '');
  const [swift, setSwift] = useState<string>(defaultBank?.swiftCode ?? '');
  const [amount, setAmount] = useState<string>('');
  const [ibanError, setIbanError] = useState<boolean>(false);

  const { _id: userId, customId } = useAppSelector((state) => state.user);
  const transactions = useAppSelector((state) => state.transactionsHistory);

  useEffect(() => {
    dispatch(getDefaultBank());
  }, []);

  useEffect(() => {
    if (Object.keys(defaultBank).length > 0) {
      setBankName(defaultBank.name);
      setIban(defaultBank.iban);
      setAccountNumber(defaultBank.accountNumber);
      setBankAddress(defaultBank.bankAddress);
      setSwift(defaultBank.swiftCode);
    }
  }, [defaultBank]);

  const formComplete = (bankName.length && iban.length && accountNumber.length && bankAddress.length && swift.length && amount.length);
  const symbolIcon = useMemo(() => (getCurrencySymbol(selectedAccount?.cfdAccountCurrency.symbol ?? '')), [selectedAccount])

  const ibanHandleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target: { value: ibanValue } } = event;

    if (ibanValue.length <= 34) {
      setIban(ibanValue);
      setIbanError(false);
    } else {
      setIbanError(true);
    }
  };

  const handleCloseDepositBtn = (): void => {
    setOpen(false);
  }

  const now = new Date();
  const formattedDate = now.toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  });

  const additionalInfo = {
    bankName,
    iban,
    accountNumber,
    bankAddress,
    swift,
    amount
  };

  const data = {
    userId,
    toAccount: userId,
    userAccountId: selectedAccount?._id ?? '',
    currencyId: selectedAccount?.cfdAccountCurrency.currencyId ?? '',
    transactionType: false,
    transactionCategory: 'balance',
    isReal: true,
    isResolved: 0,
    additionalInfo: JSON.stringify(additionalInfo),
    adding: parseFloat(amount).toPrecision(8),
    coins: Number(amount) > 0 ? amount.toString() : '0'
  };

  const handleBankDepositTransaction = async (): Promise<void> => {
    const response = await dispatch(createTransaction(data));
    const payload = response.payload as PayloadInterface;

    if (payload.isError) {
      return;
    }

    setStep(2);
    dispatch(getTransactionsHistory({ accountId: selectedAccount?._id ?? '', userId: userId ?? '' }));
  };

  const userName = `${selectedAccount?.user.firstName} ${selectedAccount?.user.lastName}`;

  const onDownloadPdf = (): void => {
    generatePdf({ scale: 2, elementId: 'invoice', fileName: 'invoice' });
  }

  const invoiceData = {
    userName,
    formattedDate,
    currencySymbol: symbolIcon,
    amount,
    accountNumber,
    bankName,
    swift,
    iban,
    bankAddress,
    customId: String(customId) ?? '',
    transacctionId: String(transactions[0]?.customId) ?? '',
    currency: selectedAccount?.cfdAccountCurrency.symbol ?? ''
  };

  return (
    <>
     { (step === 1)
       ? <>
          <div className={ style.wrapper }>
            <div className={ style.bankWrapper }>
              <p className={ style.bankWrapperText }>1. {t('deposit_modal.bank_form.subtitle')}</p>
              <div className={ style.inputContainer }>
                <label className={ style.label }>{t('labels.bank_name')}</label>
                <InputComponent value={ bankName } isCopy placeholder={t('labels.bank_name')} onChange={(e) => { setBankName(e.target.value) }} />
              </div>
              <div className={ style.inputContainer }>
                <label className={ style.label }>{t('labels.iban')}</label>
                <InputComponent value={ iban } isCopy placeholder={t('labels.iban')} onChange={ibanHandleChange} error={ibanError} />
              </div>
              <div className={ style.inputContainer }>
                <label className={ style.label }>{t('labels.account_number')}</label>
                <InputComponent value={ accountNumber } isCopy placeholder={t('labels.account_number')} onChange={(e) => { setAccountNumber(e.target.value) }} />
              </div>
              <div className={ style.inputContainer }>
                <label className={ style.label }>{t('labels.bank_address')}</label>
                <InputComponent value={ bankAddress } isCopy placeholder={t('labels.bank_address')} onChange={(e) => { setBankAddress(e.target.value) }} />
              </div>
              <div className={ style.inputContainer }>
                <label className={ style.label }>{t('labels.swift')}</label>
                <InputComponent value={ swift } isCopy placeholder={t('labels.swift')} onChange={(e) => { setSwift(e.target.value) }} />
              </div>
            </div>
            <div className={ style.bankWrapper }>
              <p className={ style.bankWrapperText }>2. {t('labels.enter_amount')}</p>
              <div className={ style.inputContainer }>
                <label htmlFor='amount' className={ style.label }>{t('labels.amount')}</label>
                <AssetAmountInputComponent value={ amount } setValue={ setAmount } id='amount' currency={selectedAccount?.cfdAccountCurrency.symbol} maxBtn={ false } placeholder='0.00'/>
              </div>
            </div>
          </div>
          <footer className={ style.footer }>
          <div className={ style.btnContainer }>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <ButtonComponent onClick={handleBankDepositTransaction} disabled={!formComplete}>
              <span className={ style.btnText }>
                {t('labels.confirm')}
              </span>
            </ButtonComponent>
          </div>
        </footer>
      </>
       : <SuccessDepositFormComponent
          title={t('labels.deposit_success')}
          onClick={ handleCloseDepositBtn }
          amount={`${amount} ${selectedAccount?.cfdAccountCurrency.symbol}`}
          account={`${selectedAccount?.type.name} ${t('labels.account')} ${selectedAccount?.cfdAccountCurrency.symbol}`}
          onDownloadPdf={onDownloadPdf}
        />
      }
        <BankDepositInvoice {...invoiceData} />
    </>
  )
}
